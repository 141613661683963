<template>
    <div class="table-page">
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>分时预约</el-breadcrumb-item>
            <el-breadcrumb-item>预约记录</el-breadcrumb-item>
        </el-breadcrumb>
        <!--        <div class="page-header">-->
        <!--            <el-page-header @back="$router.go(-1)" content="预约记录"/>-->
        <!--        </div>-->
        <div style="text-align: center" class="hidden-sm-and-down">
            <search @search="handleSearch" @export="handleExport" />
        </div>
        <div class="page-main">
            <el-table :data="table.data" style="width: 100%" height="100%" :row-class-name="tableRowClassName"
                v-loading="loading">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div style="margin-left:50px;line-height: 30px;">
                            <div>患者姓名：{{ props.row.patient }}</div>
                            <div>身份证号：{{ props.row.idnum }}</div>
                            <div>联系电话：{{ props.row.phone }}</div>
                            <div>病情简述：{{ props.row.desc }}</div>
                            <div>备&nbsp; &nbsp; &nbsp; &nbsp;注：{{ props.row.remark }}</div>
                            <div>预约时间：{{ props.row.createAt }}</div>
                            <div>到院时间：{{ props.row.daoyuanAt }}</div>
                            <div>操作账号：{{ props.row.owner }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
                    :width="v.width" :formatter="v.formatter" :key="idx">
                </el-table-column>
                <el-table-column align="center" label="操作" width="110">
                    <template slot-scope="scope">
                        <el-dropdown size="small">
                            <el-button type="default" size="small">
                                操作<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="setState(scope.row, 0)">设置状态：已预约</el-dropdown-item>
                                <el-dropdown-item @click.native="setState(scope.row, 1)">设置状态：已到院</el-dropdown-item>
                                <el-dropdown-item @click.native="setState(scope.row, 2)">设置状态：已完成</el-dropdown-item>
                                <el-dropdown-item divided></el-dropdown-item>
                                <el-dropdown-item @click.native="handleUpdate(scope.row)">修改</el-dropdown-item>
                                <el-dropdown-item @click.native="handleDel(scope)">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-footer">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]" :total="table.total"
                :page-size="table.pageSize" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        </div>
    </div>
</template>

<script>
import Search from "./components/search"
import formatter from "@/data/formatter"

export default {
    components: {
        Search
    },
    data() {
        return {
            loading: false,
            search: {},
            table: {
                columns: [
                    { title: '院区', field: 'hospital.name', width: '' },
                    { title: '科室', field: 'department.name', width: '' },
                    { title: '就诊日期', field: 'date', width: '' },
                    { title: '就诊时间', field: 'time', width: '' },
                    { title: '患者姓名', field: 'patient', width: '' },
                    { title: '医生姓名', field: 'doctor.name', width: '' },
                    { title: '预约状态', field: 'state', width: '', formatter: formatter.stateFormatter },
                    { title: '患者来源', field: 'source', width: '' },
                ],
                data: [],
                total: 0,
                page: 1,
                pageSize: 10
            },
        }
    },
    methods: {
        tableRowClassName({ row }) {
            if (row.state === -1) {
                return 'warning-row';
            } else if (row.state > 0) {
                return 'success-row';
            }
            return '';
        },
        handleCurrentChange(e) {
            this.table.page = e
            this.getData()
        },
        handleSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        handleUpdate(row) {
            this.form = row
            this.$router.push(`/main/appoint/form?id=${row.id}`)
        },
        async setState(row, state) {
            row.state = state
            const resp = await this.$http.put(`/mingde/appoint/${row.id}`, row)
            if (resp.data.code === 200) {
                this.$message.success("设置成功")
                this.getData()
            } else {
                this.$message.error("设置失败")
            }
        },
        async handleDel(scope) {
            const confirm = await this.$confirm(`确认删除 ${scope.row.patient} 的记录吗？`)
            if (!confirm) return
            const resp = await this.$http.delete(`/mingde/appoint/${scope.row.id}`)
            if (resp.data.code === 200) {
                this.$message.success("删除成功")
                this.getData()
            } else {
                this.$message.error("删除失败")
            }
        },
        async getData() {
            let params = this.search
            params['page'] = this.table.page
            params['size'] = this.table.pageSize
            const resp = await this.$http.get('/mingde/appoint/', { params: params })
            this.table.data = resp.data.data.data || []
            this.table.total = resp.data.data.total
        },
        handleSearch(e) {
            this.table.page = 1
            this.search = e
            this.getData()
        },
        async handleExport2(e) {
            let params = this.search
            params['export'] = 'xlsx'
            params['page'] = 1
            params['size'] = 50000
            const resp = await this.$http.get('/mingde/appoint/', { params: params })
            console.log(e, resp.data)
            let blob = new Blob([resp.data], { type: 'application/octet-stream;charset=utf-8;' })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
            link.click()
            window.URL.revokeObjectURL(link.href)
            this.export_loading = false
        },
        handleExport() {
            let params = this.search
            params['export'] = 'xlsx'
            params['page'] = 1
            params['size'] = 50000
            this.$http.get('/mingde/appoint/', {
                responseType: 'arraybuffer',
                xsrfHeaderName: 'Authorization',
                params: params
            }).then(res => {
                const filename = res.headers["content-disposition"]
                console.log(111, filename)
                let url = window.URL.createObjectURL(new Blob([res.data]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', filename.split("filename=")[1])
                document.body.appendChild(link)
                link.click()
            }).catch(err => {
                console.log(err)
            })
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style>
.warning-row {
    background-color: #ffc0be !important;
}

.success-row {
    background-color: #f0f9eb !important;
}
</style>
