<template>
    <el-form :model="form" :inline="true">
        <el-form-item>
            <select-hospital v-model="form.hospital_id" style="width:150px" @change="form.department_id=null;form.doctor_id=null;handleSearch()"/>
        </el-form-item>
        <el-form-item>
            <select-department v-model="form.department_id" :hospital_id="form.hospital_id" :type_id="form.type_id" style="width:100px" @change="form.doctor_id=null;handleSearch()"/>
        </el-form-item>
        <el-form-item>
            <select-doctor v-model="form.doctor_id" :hospital_id="form.hospital_id" :type_id="form.type_id" :department_id="form.department_id" style="width:100px" @change="handleSearch"/>
        </el-form-item>
        <el-form-item>
            <el-select v-model="form.source" placeholder="选择来源" style="width: 100px" size="small" clearable @change="handleSearch">
                <el-option label="微信预约" value="微信预约"></el-option>
                <el-option label="电话预约" value="电话预约"></el-option>
                <el-option label="VIP通道" value="VIP通道"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-select v-model="form.state" placeholder="选择状态" style="width: 100px" size="small" clearable @change="handleSearch">
                <el-option label="已预约" value="0"></el-option>
                <el-option label="已取消" value="-1"></el-option>
                <el-option label="已沟通" value="3"></el-option>
                <el-option label="已到院" value="1"></el-option>
                <el-option label="已完成" value="2"></el-option>
            </el-select>
        </el-form-item>
<!--        <el-form-item label="就诊日期">-->
<!--            <el-date-picker style="width: 140px"-->
<!--                            v-model="form.date"-->
<!--                            type="date"-->
<!--                            align="right"-->
<!--                            unlink-panels-->
<!--                            format="yyyy-MM-dd"-->
<!--                            value-format="yyyy-MM-dd"-->
<!--                            size="small"-->
<!--            >-->
<!--            </el-date-picker>-->
<!--        </el-form-item>-->
        <el-form-item>
            <el-date-picker
                    style="width: 250px"
                    size="small"
                    v-model="form.daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="至"
                    @change="handleSearch"
                    clearable
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item>
            <el-input v-model="form.keywords" placeholder="关键词" style="width: 120px" size="small" @blur="handleSearch" @keyup.enter.native="handleSearch" clearable></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button type="default" @click="handleExport" size="small">导出</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
    export default {
        data() {
            let now = new Date()
            let today = now.format("yyyy-MM-dd")
            return {
                hospitalList: [],
                departmentList: [],
                form: {
                    source: '',
                    type: '',
                    hospital_id: '',
                    department_id: '',
                    doctor_id: '',
                    date: '',
                    daterange: [today, today],
                    keywords: '',
                }
            }
        },
        methods: {
            handleSearch() {
                this.$emit('search', this.form);
            },
            handleExport() {
                this.$emit('export', this.form);
            },
            async getData() {
                const hp = await this.$http.get(`/mingde/hospital/`)
                this.hospitalList = hp.data.data
                if (this.form.hospital_id){
                    const dt = await this.$http.get(`/mingde/department/?type=${this.form.type}&hospital_id=${this.form.hospital_id}`)
                    this.departmentList = dt.data.data
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
